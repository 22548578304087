import config from '../../configs/config';
import banner1 from '../../Assets/images/home/banner/banner-item-th-1.webp';
import banner2 from '../../Assets/images/home/banner/banner-item-th-2.webp';
import banner3 from '../../Assets/images/home/banner/banner-item-th-3.webp';
import banner4 from '../../Assets/images/home/banner/banner-item-th-4.webp';
import banner5 from '../../Assets/images/home/banner/banner-item-th-5.webp';
import banner6 from '../../Assets/images/home/banner/banner-item-th-6.webp';
import banner7 from '../../Assets/images/home/banner/banner-item-en-7.webp';
import promotion1 from '../../Assets/images/home/promotion/promotion-item-th-1.webp';
import promotion2 from '../../Assets/images/home/promotion/promotion-item-th-2.webp';
import promotion3 from '../../Assets/images/home/promotion/promotion-item-th-3.webp';
import promotion4 from '../../Assets/images/home/promotion/promotion-item-th-4.webp';
import promotion5 from '../../Assets/images/home/promotion/promotion-item-th-5.webp';
import tunetouch from '../../Assets/images/tunetouch_th.webp';
import icon1 from '../../Assets/images/icons/icon01.png';
import icon2 from '../../Assets/images/icons/icon02.png';
import icon3 from '../../Assets/images/icons/icon03.png';
import icon4 from '../../Assets/images/icons/icon04.png';
import icon5 from '../../Assets/images/icons/icon05.png';
import icon6 from '../../Assets/images/icons/icon06.png';

export default {
    banners: [
        // {
        //     original: banner6,
        //     thumbnail: banner6,
        //     url: 'product/travel-insurance/ONTAOB'
        // },
        {
            original: banner3,
            thumbnail: banner3,
            url: 'product/travel-insurance/ONTAOB'
        },
        {
            original: banner2,
            thumbnail: banner2,
            url: 'product/travel-insurance/TAIPOCT22'
        },
        {
            original: banner1,
            thumbnail: banner1,
            url: 'product/travel-insurance/TAISM'
        },
        // {
        //     original: banner5,
        //     thumbnail: banner5,
        //     url: 'product/pa-choice-insurance'
        // },
        {
            original: banner7,
            thumbnail: banner7,
            url: 'th/news/news.content_836'
        },
        {
            original: banner4,
            thumbnail: banner4,
            url: ''
        },
    ],
    products: {
        topic: 'ผลิตภัณฑ์ของเรา',
        content: [
            { title: 'เดินทาง', alt: 'ประกันการเดินทางภายในประเทศและต่างประเทศ', icon: icon1, highlight: true, link: 'product/travel-insurance' },
            { title: 'ชดเชยรายได้', alt: 'ประกันชดเชยรายได้', icon: icon2, highlight: true, link: 'product/health/ONCSHC' },
            { title: 'สุขภาพ', alt: 'ประกันสุขภาพและโรคร้าย', icon: icon3, highlight: false, link: 'product/health' },
            { title: 'อุบัติเหตุ', alt: 'ประกัน PA ประกันภัยอุบัติเหตุส่วนบุคคล', icon: icon4, highlight: false, link: 'product/pa-choice-insurance' },
            { title: 'บ้านและอัคคีภัย', alt: 'ประกันภัยสำหรับที่อยู่อาศัย', icon: icon5, highlight: false, link: 'product/fire-myhomeplus' },
            { title: 'อื่นๆ', alt: 'ประกันอื่นๆ', icon: icon6, highlight: false, link: '' }
        ]
    },
    promotions: {
        topic: 'โปรโมชั่น',
        content: [
            // { banner: promotion5, description: 'เชียร์สุดใจ โอลิมปิค 2024 รับฟรี! เสื้อแทร็คสูท Grand Sport Olympic 2024 Collection เมื่อซื้อประกันเดินทางต่างประเทศรายปี', buttonDetail: 'ดูรายละเอียด', buttonBuy: 'ซื้อเลย', promotionLink: `${config.webUrlRedirect}/th/promotion/promotion.content_851`, link: `${config.webUrlRedirect}/th/product/travel-insurance/ONTAOB` },
            { banner: promotion3, description: 'หน้าฝนก็เที่ยวได้ อุ่นใจทุกทริป เที่ยวให้ฟินหน้าฝน ซื้อประกันเดินทางต่างประเทศ ไอทราเวล ผ่านเว็บไซต์ รับเลย! บัตรกำนัลสตาร์บัคส์ สูงสุดถึง 1,200 บาท​', buttonDetail: 'ดูรายละเอียด', buttonBuy: 'ซื้อเลย', promotionLink: `${config.webUrlRedirect}/th/promotion/promotion.content_844`, link: `${config.webUrlRedirect}/th/product/travel-insurance/ONTAOB` },
            { banner: promotion2, description: 'Early Bird Promotion: Get more when you buy early!​', buttonDetail: 'ดูรายละเอียด', buttonBuy: 'ซื้อเลย', promotionLink: `${config.webUrlRedirect}/th/promotion/promotion.content_845`, link: `${config.webUrlRedirect}/en/product/travel-insurance/TAIPOCT22` },
            { banner: promotion1, description: 'Purchase iSmile today to receive Grab Transportation e-Coupon up to 700 baht', buttonDetail: 'ดูรายละเอียด', buttonBuy: 'ซื้อเลย', promotionLink: `${config.webUrlRedirect}/th/promotion/promotion.content_846`, link: `${config.webUrlRedirect}/en/product/travel-insurance/TAISM` },
            // { banner: promotion4, description: 'ซื้อประกันอุบัติเหตุ วันนี้สุดคุ้ม! เลือกรับได้ทันที e-Voucher เซ็นทรัลหรือสตาร์บัคส์ มูลค่าสูงสุด 1,000 บาท', buttonDetail: 'ดูรายละเอียด', buttonBuy: 'ซื้อเลย', promotionLink: `${config.webUrlRedirect}/th/promotion/promotion.content_849`, link: `${config.webUrlRedirect}/th/product/pa-choice-insurance` },
        ],
        all: 'โปรโมชั่นทั้งหมด'
    },
    all: 'ดูทั้งหมด',
    blogs: {
        topic: 'บทความแนะนำ', 
        buttonDetail: 'รายละเอียด',
        all: 'บทความทั้งหมด'
    },
    about: {
        topic: 'Tune Protect ประกันง่ายๆ แค่ปลายนิ้วคลิก',
        title1: 'Insurance Simplified',
        subTitle1: 'รับความคุ้มครองตอบโจทย์ ให้คุณสบายใจทุกการใช้ชีวิต ด้วยแบบประกันที่เข้าใจง่าย ซื้อสะดวกผ่านเว็บไซต์',
        title2: 'ขั้นตอนรับความคุ้มครอง',
        items: [
            {
                number: 1,
                title: 'เลือกแบบประกัน',
                subTitle: 'เลือกแบบประกันที่ต้องการรับความคุ้มครอง'
            },
            {
                number: 2,
                title: 'เลือกแผน',
                subTitle: 'เปรียบเทียบ และเลือกแผนประกันที่ต้องการ'
            },
            {
                number: 3,
                title: 'กรอกข้อมูล',
                subTitle: 'กรอกรายละเอียดข้อมูลส่วนบุคคลให้เรียบร้อย'
            },
            {
                number: 4,
                title: 'ยืนยันข้อมูลการชำระเงิน',
                subTitle: 'ตรวจสอบข้อมูลยืนยันความถูกต้อง และชำระเงิน'
            }
        ]
    },
    tunetouch: {
        banner: tunetouch
    },
    review: {
        topic: 'เสียงตอบรับจากลูกค้า'
    },
    thankyou: {
        header: 'ขอขอบคุณที่ไว้วางใจทำประกันกับ Tune Protect',
        lable1: 'ความคุ้มครองของท่านได้เริ่มขึ้นแล้ว',
        lable2: 'ตรวจสอบรายละเอียดการสมัครซื้อกรมธรรม์ประกันภัยของคุณได้ตามช่องทางที่ท่านได้ระบุไว้ขณะทำรายการสั่งซื้อ',
        refCode: 'หมายเลขอ้างอิง: ',
        button: 'กลับสู่หน้าหลัก',
    },
}