import config from '../../configs/config';
import banner1 from '../../Assets/images/home/banner/banner-item-en-1.webp';
import banner2 from '../../Assets/images/home/banner/banner-item-en-2.webp';
import banner3 from '../../Assets/images/home/banner/banner-item-en-3.webp';
import banner4 from '../../Assets/images/home/banner/banner-item-en-4.webp';
import banner5 from '../../Assets/images/home/banner/banner-item-en-5.webp';
import banner6 from '../../Assets/images/home/banner/banner-item-en-6.webp';
import banner7 from '../../Assets/images/home/banner/banner-item-en-7.webp';
import promotion1 from '../../Assets/images/home/promotion/promotion-item-en-1.webp';
import promotion2 from '../../Assets/images/home/promotion/promotion-item-en-2.webp';
import promotion3 from '../../Assets/images/home/promotion/promotion-item-en-3.webp';
import promotion4 from '../../Assets/images/home/promotion/promotion-item-en-4.webp';
import promotion5 from '../../Assets/images/home/promotion/promotion-item-en-5.webp';
import tunetouch from '../../Assets/images/tunetouch_en.webp';
import icon1 from '../../Assets/images/icons/icon01.png';
import icon2 from '../../Assets/images/icons/icon02.png';
import icon3 from '../../Assets/images/icons/icon03.png';
import icon4 from '../../Assets/images/icons/icon04.png';
import icon5 from '../../Assets/images/icons/icon05.png';
import icon6 from '../../Assets/images/icons/icon06.png';

export default {
    banners: [
        // {
        //     original: banner6,
        //     thumbnail: banner6,
        //     url: 'product/travel-insurance/ONTAOB'
        // },
        {
            original: banner3,
            thumbnail: banner3,
            url: 'product/travel-insurance/ONTAOB'
        },
        {
            original: banner2,
            thumbnail: banner2,
            url: 'product/travel-insurance/TAIPOCT22'
        },
        {
            original: banner1,
            thumbnail: banner1,
            url: 'product/travel-insurance/TAISM'
        },
        // {
        //     original: banner5,
        //     thumbnail: banner5,
        //     url: 'product/pa-choice-insurance'
        // },
        {
            original: banner7,
            thumbnail: banner7,
            url: 'en/news/news.content_836'
        },
        {
            original: banner4,
            thumbnail: banner4,
            url: ''
        },
    ],
    products: {
        topic: 'Our Products',
        content: [
            { title: 'Travel', alt: 'Travel', icon: icon1, highlight: true, link: 'product/travel-insurance' },
            { title: 'Hospital Cash', alt: 'Hospital Cash', icon: icon2, highlight: true, link: 'product/health/ONCSHC' },
            { title: 'Health & CI', alt: 'Health & CI', icon: icon3, highlight: false, link: 'product/health' },
            { title: 'Personal Accident', alt: 'Personal Accident', icon: icon4, highlight: false, link: 'product/pa-choice-insurance' },
            { title: 'Home', alt: 'Home', icon: icon5, highlight: false, link: 'product/fire-myhomeplus' },
            { title: 'Other', alt: 'Other', icon: icon6, highlight: false, link: '' }
        ]
    },
    promotions: {
        topic: 'Promotion',
        content: [
            // { banner: promotion5, description: 'เชียร์สุดใจ โอลิมปิค 2024 รับฟรี! เสื้อแทร็คสูท Grand Sport Olympic 2024 Collection เมื่อซื้อประกันเดินทางต่างประเทศรายปี​​​', buttonDetail: 'See more', buttonBuy: 'Buy now', promotionLink: `${config.webUrlRedirect}/en/promotion/promotion.content_851`, link: `${config.webUrlRedirect}/en/product/travel-insurance/ONTAOB` },
            { banner: promotion3, description: 'หน้าฝนก็เที่ยวได้ อุ่นใจทุกทริป เที่ยวให้ฟินหน้าฝน ซื้อประกันเดินทางต่างประเทศ ไอทราเวล ผ่านเว็บไซต์ รับเลย! บัตรกำนัลสตาร์บัคส์ สูงสุดถึง 1,200 บาท​​​', buttonDetail: 'See more', buttonBuy: 'Buy now', promotionLink: `${config.webUrlRedirect}/en/promotion/promotion.content_844`, link: `${config.webUrlRedirect}/en/product/travel-insurance/ONTAOB` },
            { banner: promotion2, description: 'Early Bird Promotion: Get more when you buy early!​​', buttonDetail: 'See more', buttonBuy: 'Buy now', promotionLink: `${config.webUrlRedirect}/en/promotion/promotion.content_845`, link: `${config.webUrlRedirect}/en/product/travel-insurance/TAIPOCT22` },
            { banner: promotion1, description: 'Purchase iSmile today to receive Grab Transportation e-Coupon up to 700 baht', buttonDetail: 'See more', buttonBuy: 'Buy now', promotionLink: `${config.webUrlRedirect}/en/promotion/promotion.content_846`, link: `${config.webUrlRedirect}/en/product/travel-insurance/TAISM` },
            // { banner: promotion4, description: 'ซื้อประกันอุบัติเหตุ วันนี้สุดคุ้ม! เลือกรับได้ทันที e-Voucher เซ็นทรัลหรือสตาร์บัคส์ มูลค่าสูงสุด 1,000 บาท', buttonDetail: 'See more', buttonBuy: 'Buy now', promotionLink: `${config.webUrlRedirect}/en/promotion/promotion.content_849`, link: `${config.webUrlRedirect}/en/product/pa-choice-insurance` },
        ],
        all: 'View All Promotion'
    },
    all: 'View All',
    blogs: {
        topic: 'Blog & Article',
        buttonDetail: 'Read more',
        all: 'View All Blog & Article'
    },
    about: {
        topic: 'Tune Protect Easy insurance at your fingertips, Click',
        title1: 'Insurance Simplified',
        subTitle1: 'Get coverage Let you feel comfortable in every life. with an insurance form that is easy to understand Easy to buy through the website',
        title2: 'Procedures for receiving protection',
        items: [
            {
                number: 1,
                title: 'Select Insurance',
                subTitle: 'Choose the type of insurance to receive coverage'
            },
            {
                number: 2,
                title: 'Select plan',
                subTitle: 'Compare coverage and choose the desired insurance plan'
            },
            {
                number: 3,
                title: 'Fill in',
                subTitle: 'Complete personal information details'
            },
            {
                number: 4,
                title: 'Confirm and payment',
                subTitle: 'Verify information, confirm accuracy, and make payment'
            }
        ]
    },
    tunetouch: {
        banner: tunetouch
    },
    review: {
        topic: 'Customer Feedback'
    },
    thankyou: {
        header: 'Thank you for choosing Tune Protect',
        lable1: 'Your coverage starts now',
        lable2: 'Please check your insurance policy following your specified channel. Your policy number for your',
        refCode: 'Reference Code: ',
        button: 'BACK TO MAIN PAGE',
    },
}