import logo from '../../Assets/images/tune-logo.png';
import { Menubar } from 'primereact/menubar';
import { SelectButton } from 'primereact/selectbutton';
import { useDispatch, useSelector } from 'react-redux';
import languageSlice from "../../Redux/languageSlice";
import config from '../../configs/config';
import { useNavigate } from 'react-router';

export default function Header(props) {


    const navigate = useNavigate();

    const dispatch = useDispatch()
    const { setLanguege } = languageSlice.actions
    const { languageSelected } = useSelector((state) => state.language)

    const options = ['TH', 'EN']

    const changeLanguege = (data) => {
        if (data && data != languageSelected) dispatch(setLanguege(data))
        sessionStorage.setItem('language', data);
        if (window.OneTrust) {
            window.OneTrust.changeLanguage(data.toLowerCase())
        }
    }

    const executeScroll = () => props.bussinesRef.current.scrollIntoView()

    const menuItems = () => {
        if (languageSelected === 'TH') {
            return [
                {
                    label: 'ผลิตภัณฑ์',
                    items: [
                        {
                            label: 'ประกันสุขภาพและโรคร้ายแรง',
                            items: [
                                {
                                    label: 'ชิลชัวร์',
                                    command: () => { window.location.href = `${config.webUrlRedirect}/${languageSelected.toLowerCase()}/product/health/ONCSHC` }
                                },
                                {
                                    label: 'อี-แคนเซอร์ แคร์',
                                    command: () => { navigate('/product/cancer') }
                                },
                                {
                                    label: 'มายเฟล็กซี่ ซี ไอ',
                                    command: () => { window.location.href = `${config.webUrlRedirect}/${languageSelected.toLowerCase()}/product/health/CI` }
                                },
                                {
                                    label: 'เบาหวาน โพรเทค',
                                    command: () => { window.location.href = `${config.webUrlRedirect}/${languageSelected.toLowerCase()}/product/health/DIABETES` }
                                }
                            ]
                        },
                        {
                            label: 'ประกันภัยอุบัติเหตุส่วนบุคคล',
                            items: [
                                {
                                    label: 'พีเอ ช้อยส์ แคร์',
                                    command: () => { window.location.href = `${config.webUrlRedirect}/${languageSelected.toLowerCase()}/product/pa-choice-insurance/ONPACA` }
                                },
                                {
                                    label: 'พีเอ ช้อยส์ คิดส์',
                                    command: () => { window.location.href = `${config.webUrlRedirect}/${languageSelected.toLowerCase()}/product/pa-choice-insurance/ONPAKD` }
                                },
                                {
                                    label: 'พีเอ ช้อยส์ ซีเนียร์',
                                    command: () => { window.location.href = `${config.webUrlRedirect}/${languageSelected.toLowerCase()}/product/pa-choice-insurance/ONPASN` }
                                }
                            ]
                        },
                        {
                            label: 'ประกันภัยการเดินทาง',
                            items: [
                                {
                                    label: 'ไอสมายด์',
                                    command: () => { window.location.href = `${config.webUrlRedirect}/${languageSelected.toLowerCase()}/product/travel-insurance/TAISM` }
                                },
                                {
                                    label: 'ทูน ไอพาส',
                                    command: () => { window.location.href = `${config.webUrlRedirect}/${languageSelected.toLowerCase()}/product/travel-insurance/TAIPOCT22` }
                                },
                                {
                                    label: 'ภายในประเทศ',
                                    command: () => { window.location.href = `${config.webUrlRedirect}/${languageSelected.toLowerCase()}/product/travel-insurance/ONTADM` }
                                },
                                {
                                    label: 'ไอ ทราเวล ประกันการเดินทางต่างประเทศ',
                                    command: () => { window.location.href = `${config.webUrlRedirect}/${languageSelected.toLowerCase()}/product/travel-insurance/ONTAOB` }
                                }
                            ]
                        },
                        {
                            label: 'ประกันภัยสำหรับที่อยู่อาศัย',
                            items: [
                                {
                                    label: 'มายโฮม พลัส',
                                    command: () => { window.location.href = `${config.webUrlRedirect}/${languageSelected.toLowerCase()}/product/fire-myhomeplus/ONFIMP` }
                                },
                                {
                                    label: 'มายโฮม สมาร์ท',
                                    command: () => { window.location.href = `${config.webUrlRedirect}/${languageSelected.toLowerCase()}/product/fire-myhomeplus/ONMHS` }
                                }
                            ]
                        }
                    ]
                },
                {
                    label: 'โปรโมชั่น',
                    command: () => { window.location.href = `${config.webUrlRedirect}/${languageSelected.toLowerCase()}/promotion` }
                },
                {
                    label: 'ข่าวสารและกิจกรรม',
                    command: () => { window.location.href = `${config.webUrlRedirect}/${languageSelected.toLowerCase()}/news` }
                },
                {
                    label: 'บริการ',
                    items: [
                        {
                            label: 'EVAC/REPAT',
                            command: () => { window.location.href = `${config.webUrlRedirect}/${languageSelected.toLowerCase()}/service/evacrepat` }
                        },
                        {
                            label: 'Fixit',
                            command: () => { window.location.href = `${config.webUrlRedirect}/${languageSelected.toLowerCase()}/service/fixit` }
                        },
                        {
                            label: 'Health2Go',
                            command: () => { window.location.href = `${config.webUrlRedirect}/${languageSelected.toLowerCase()}/service/health2go` }
                        },
                        {
                            label: 'myEliteDoctor',
                            command: () => { window.location.href = `${config.webUrlRedirect}/${languageSelected.toLowerCase()}/service/mso` }
                        },
                        {
                            label: 'Dfit',
                            command: () => { window.location.href = `${config.webUrlRedirect}/${languageSelected.toLowerCase()}/service/dfit` }
                        },
                        {
                            label: 'BloodTest',
                            command: () => { window.location.href = `${config.webUrlRedirect}/${languageSelected.toLowerCase()}/service/bloodtest` }
                        }
                    ]
                },
                {
                    label: 'เรียกร้องสินไหม',
                    command: () => { window.location.href = `${config.webUrlRedirect}/${languageSelected.toLowerCase()}/claim` }
                },
                {
                    label: 'บทความแนะนำ',
                    command: () => { window.location.href = `${config.webUrlRedirect}/${languageSelected.toLowerCase()}/article` }
                },
                {
                    label: 'ติดต่อเรา',
                    command: () => { window.location.href = `${config.webUrlRedirect}/${languageSelected.toLowerCase()}/contactus2` }
                },
                {
                    label: 'เกี่ยวกับ Tune Protect',
                    items: [
                        {
                            label: 'ประวัติองค์กร',
                            command: () => { window.location.href = `${config.webUrlRedirect}/${languageSelected.toLowerCase()}/aboutus/organization` }
                        },
                        {
                            label: 'การกำกับดูแลกิจการ',
                            command: () => { window.location.href = `${config.webUrlRedirect}/${languageSelected.toLowerCase()}/aboutus/corporate-governance` }
                        },
                        {
                            label: 'รายงานประจำปี',
                            command: () => { window.location.href = `${config.webUrlRedirect}/${languageSelected.toLowerCase()}/aboutus/annual_report` }
                        },
                        {
                            label: 'ข้อมูลสำคัญทางการเงิน',
                            command: () => { window.location.href = `${config.webUrlRedirect}/${languageSelected.toLowerCase()}/aboutus/financial-statement` }
                        }
                    ]
                },
                {
                    label: 'ประกันภัยสำหรับธุรกิจ',
                    command: executeScroll
                }
            ]
        } else {
            return [
                {
                    label: 'Product',
                    items: [
                        {
                            label: 'Health and Critical Illness Insurance',
                            items: [
                                {
                                    label: 'Chill Sure',
                                    command: () => { window.location.href = `${config.webUrlRedirect}/${languageSelected.toLowerCase()}/product/health/ONCSHC` }
                                },
                                {
                                    label: 'E-Cancer Care',
                                    command: () => { navigate('/product/cancer') }
                                },
                                {
                                    label: 'myFlexi CI',
                                    command: () => { window.location.href = `${config.webUrlRedirect}/${languageSelected.toLowerCase()}/product/health/CI` }
                                },
                                {
                                    label: 'Bao Wan Protect',
                                    command: () => { window.location.href = `${config.webUrlRedirect}/${languageSelected.toLowerCase()}/product/health/DIABETES` }
                                }
                            ]
                        },
                        {
                            label: 'PA Choice',
                            items: [
                                {
                                    label: 'PA Choice Care',
                                    command: () => { window.location.href = `${config.webUrlRedirect}/${languageSelected.toLowerCase()}/product/pa-choice-insurance/ONPACA` }
                                },
                                {
                                    label: 'PA Choice Kids',
                                    command: () => { window.location.href = `${config.webUrlRedirect}/${languageSelected.toLowerCase()}/product/pa-choice-insurance/ONPAKD` }
                                },
                                {
                                    label: 'PA Choice Senior',
                                    command: () => { window.location.href = `${config.webUrlRedirect}/${languageSelected.toLowerCase()}/product/pa-choice-insurance/ONPASN` }
                                }
                            ]
                        },
                        {
                            label: 'Travel Insurance',
                            items: [
                                {
                                    label: 'iSmile',
                                    command: () => { window.location.href = `${config.webUrlRedirect}/${languageSelected.toLowerCase()}/product/travel-insurance/TAISM` }
                                },
                                {
                                    label: 'Tune iPass',
                                    command: () => { window.location.href = `${config.webUrlRedirect}/${languageSelected.toLowerCase()}/product/travel-insurance/TAIPOCT22` }
                                },
                                {
                                    label: 'TA Domestic',
                                    command: () => { window.location.href = `${config.webUrlRedirect}/${languageSelected.toLowerCase()}/product/travel-insurance/ONTADM` }
                                },
                                {
                                    label: 'iTravel International travel insurance',
                                    command: () => { window.location.href = `${config.webUrlRedirect}/${languageSelected.toLowerCase()}/product/travel-insurance/ONTAOB` }
                                }
                            ]
                        },
                        {
                            label: 'Fire insurance',
                            items: [
                                {
                                    label: 'myHomePlus',
                                    command: () => { window.location.href = `${config.webUrlRedirect}/${languageSelected.toLowerCase()}/product/fire-myhomeplus/ONFIMP` }
                                },
                                {
                                    label: 'myHomeSmar',
                                    command: () => { window.location.href = `${config.webUrlRedirect}/${languageSelected.toLowerCase()}/product/fire-myhomeplus/ONMHS` }
                                }
                            ]
                        }
                    ]
                },
                {
                    label: 'Promotion',
                    command: () => { window.location.href = `${config.webUrlRedirect}/${languageSelected.toLowerCase()}/promotion` }
                },
                {
                    label: 'News & Activity',
                    command: () => { window.location.href = `${config.webUrlRedirect}/${languageSelected.toLowerCase()}/news` }
                },
                {
                    label: 'Services',
                    items: [
                        {
                            label: 'EVAC/REPAT',
                            command: () => { window.location.href = `${config.webUrlRedirect}/${languageSelected.toLowerCase()}/service/evacrepat` }
                        },
                        {
                            label: 'Fixit',
                            command: () => { window.location.href = `${config.webUrlRedirect}/${languageSelected.toLowerCase()}/service/fixit` }
                        },
                        {
                            label: 'Health2Go',
                            command: () => { window.location.href = `${config.webUrlRedirect}/${languageSelected.toLowerCase()}/service/health2go` }
                        },
                        {
                            label: 'myEliteDoctor',
                            command: () => { window.location.href = `${config.webUrlRedirect}/${languageSelected.toLowerCase()}/service/mso` }
                        },
                        {
                            label: 'Dfit',
                            command: () => { window.location.href = `${config.webUrlRedirect}/${languageSelected.toLowerCase()}/service/dfit` }
                        },
                        {
                            label: 'BloodTest',
                            command: () => { window.location.href = `${config.webUrlRedirect}/${languageSelected.toLowerCase()}/service/bloodtest` }
                        }
                    ]
                },
                {
                    label: 'Claim Service',
                    command: () => { window.location.href = `${config.webUrlRedirect}/${languageSelected.toLowerCase()}/claim` }
                },
                {
                    label: 'Blog',
                    command: () => { window.location.href = `${config.webUrlRedirect}/${languageSelected.toLowerCase()}/article` }
                },
                {
                    label: 'Contact Us',
                    command: () => { window.location.href = `${config.webUrlRedirect}/${languageSelected.toLowerCase()}/contactus2` }
                },
                {
                    label: 'About Tune Protect',
                    items: [
                        {
                            label: 'Company Profile',
                            command: () => { window.location.href = `${config.webUrlRedirect}/${languageSelected.toLowerCase()}/aboutus/organization` }
                        },
                        {
                            label: 'Corporate Governance',
                            command: () => { window.location.href = `${config.webUrlRedirect}/${languageSelected.toLowerCase()}/aboutus/corporate-governance` }
                        },
                        {
                            label: 'Annual Report',
                            command: () => { window.location.href = `${config.webUrlRedirect}/${languageSelected.toLowerCase()}/aboutus/annual_report` }
                        },
                        {
                            label: 'Financials & Investments',
                            command: () => { window.location.href = `${config.webUrlRedirect}/${languageSelected.toLowerCase()}/aboutus/financial-statement` }
                        }
                    ]
                },
                {
                    label: 'Business Insurance',
                    command: executeScroll
                }
            ]
        }
    }

    return (
        <div className="box-navbar">
            {/* Fixed bar */}
            <nav className="fixed bg-white nav-fixed hidden-sm">
                <div className='grid'>
                    <div className="col-1 pb-0">
                        <h1 className='cursor-pointer' onClick={() => navigate('/')}>
                            <img src={logo} className='logo h-3rem p-1' />
                        </h1>
                    </div>
                    <div className="col-9 pb-0">
                        <Menubar model={menuItems()} />
                    </div>
                    <div className="col-2 mt-1 pb-0">
                        <SelectButton className="language" value={languageSelected} onChange={(e) => changeLanguege(e.value)} options={options} />
                    </div>
                </div>
            </nav>

            <nav className="fixed bg-white hidden-md nav-mobile px-2">
                <ul className="list-none p-0 mb-0">
                    <li><Menubar model={menuItems()} /></li>
                    <li className="logo-mobile"><img src={logo} className='h-2rem' /></li>
                    <li className="language-mobile"> <SelectButton className="language" value={languageSelected} onChange={(e) => changeLanguege(e.value)} options={options} /></li>
                </ul>
            </nav>
        </div>
    );
}